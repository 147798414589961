import type { useAllEmployees, useCurrentEmployeeTips } from '@hooks';
import { PrimaryButton } from '@components/ui';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Select, { MultiValue, StylesConfig } from 'react-select';
import {
  WebTeamHashTagSchema,
  WebEmployeeForCardCreationSchema,
  WebMeSchema,
  WebCardsSchema,
  WebCardsPostSchema,
} from 'src/__generated__/@types';
import { UserIcon } from '@components/common';
import { useForm, Controller, type FieldErrors } from 'react-hook-form';
import { api } from '@lib/api';
import { notifyError, notifySuccess } from '@lib/notification';
import { rollbar } from '@lib/rollbar';
import TextareaAutosize from 'react-textarea-autosize';
import { KeyedMutator } from 'swr';

type Props = {
  tenantId: WebMeSchema['tenant_id'];
  employees: ReturnType<typeof useAllEmployees>['fetchedEmployeesData'];
  currentEmployeeTips: ReturnType<typeof useCurrentEmployeeTips>['data'];
  teamHashTags: WebTeamHashTagSchema[];
  currentEmployeeMutate: KeyedMutator<WebMeSchema>;
  cardsMutate: KeyedMutator<{ cards: WebCardsSchema; has_next: boolean }[]>;
};

type EmployeeOption = {
  // employee.id
  value: number;
  // employee.display_name
  label: string;
  // Used in `getOptionLabel` to be able to search.
  realName?: string;
  avatar?: string;
};

type TeamHashTagOption = {
  // teamHashTag.id
  value: number;
  // teamHashTag.name
  label: string;
};

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid ${({ theme: { colors } }) => colors.base.border.main};
  background-color: ${({ theme: { colors } }) => colors.base.bg.main};
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Form = styled.form`
  display: contents;
`;

const FormLabel = styled.h2`
  width: 100%;
  font-weight: bold;
  margin-bottom: 4px;
`;

const FormSubLabel = styled.span<{ error: boolean }>`
  font-size: 14px;
  font-weight: normal;
  margin-left: 4px;
  ${({ error, theme }) =>
    error &&
    ` color: ${theme.colors.error.main};
      font-weight: bold;
    `}
`;

const ErrorLabel = styled.span`
  margin-left: 8px;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.colors.error.main};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    display: block;
  }
`;

const FlexItem = styled.div``;

const ToEmployeeAndPointArea = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 160px;
  align-items: start;
  gap: 8px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr;
  }
`;

const ToEmployeeSelectArea = styled.div``;

const PointInputArea = styled.div`
  width: 100%;
`;

const PointInput = styled.input.attrs({ type: 'number' })<{ error: boolean }>`
  width: 100%;
  padding: 8px;
  height: 44px;
  border: 1px solid ${({ theme: { colors } }) => colors.base.border.main};
  border-radius: 4px;
  ${({ error, theme }) =>
    error &&
    ` outline: none;
      border: 2px solid ${theme.colors.error.main};
      background-color: ${theme.colors.error.light};
    `}
`;

const MessageTextArea = styled(TextareaAutosize)<{ error: boolean }>`
  width: 100%;
  resize: none;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid ${({ theme: { colors } }) => colors.base.border.main};
  ${({ error, theme }) =>
    error &&
    ` outline: none;
      border: 2px solid ${theme.colors.error.main};
      background-color: ${theme.colors.error.light};
    `}
`;

const ButtonWrapper = styled.div`
  max-width: 200px;
  margin: 0 auto;
`;

// 従業員情報を react-select の option に変換する
const convertEmployeeToSelectOption = (
  employee: WebEmployeeForCardCreationSchema
): EmployeeOption => {
  return {
    value: employee.id,
    label: employee.display_name,
    realName: employee.real_name,
    avatar: employee.avatar,
  };
};

const convertTeamHashTagToSelectOption = (
  teamHashTag: WebTeamHashTagSchema
): TeamHashTagOption => {
  return {
    value: teamHashTag.id,
    label: teamHashTag.name,
  };
};

const EmployeeSelectLabelWrapper = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 8px;
  p {
    display: flex;
    align-items: center;
  }
`;

const EmployeeSelectLabel: FC<{ option: EmployeeOption }> = ({
  option: { value, label, avatar },
}) => {
  return (
    <EmployeeSelectLabelWrapper>
      <UserIcon size={24} id={value} avatar={avatar} real_name={label} />
      <p>{label}</p>
    </EmployeeSelectLabelWrapper>
  );
};

const ToEmployeeError = ({
  error,
}: {
  error: FieldErrors<Required<WebCardsPostSchema>>['to_employee_ids'];
}) => {
  if (!error) {
    return <></>;
  }
  if (Array.isArray(error)) {
    return (
      <>
        {error.map((e, i) => {
          return <ErrorLabel key={i}>{e.message}</ErrorLabel>;
        })}
      </>
    );
  }
  return <ErrorLabel>{error.message}</ErrorLabel>;
};

// TODO: theme.ts の値を使う
const selectStyles = (
  error?: boolean
): StylesConfig<EmployeeOption | TeamHashTagOption> => {
  return {
    control: (styles) => ({
      ...styles,
      border: error ? '2px solid #e01e1e' : '1px solid #d8d8df',
      backgroundColor: error ? '#ffeded' : '#fff',
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? '#ff630d' : '#fff',
      color: isFocused ? '#fff' : '#474747',
      fontWeight: isFocused ? 'bold' : 'normal',
    }),
  };
};

const addHashTagsToMessage = (
  message: string,
  hashTags: MultiValue<TeamHashTagOption>
): string => {
  if (!hashTags.length) return message;
  const hashTagsJoin = hashTags.map(({ label }) => `#${label}`).join(' ');
  return `${message} ${hashTagsJoin}`;
};

export const CardCreateForm: FC<Props> = ({
  tenantId,
  employees,
  currentEmployeeTips,
  teamHashTags,
  currentEmployeeMutate,
  cardsMutate,
}) => {
  const messageMaxLength = 400;
  const [selectedTeamHashTags, setSelectedTeamHashTags] = useState<
    MultiValue<TeamHashTagOption>
  >([]);
  const handleChangeTeamHashTagSelect = useCallback(
    (choices: MultiValue<TeamHashTagOption>) => {
      setSelectedTeamHashTags(choices);
    },
    []
  );

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    control,
    setError,
    clearErrors,
  } = useForm<Required<WebCardsPostSchema>>({
    mode: 'onChange',
    defaultValues: {
      to_employee_ids: [],
      amount: undefined,
      message: '',
    },
  });

  const validateAmount = (
    type: Extract<keyof WebCardsPostSchema, 'to_employee_ids' | 'amount'>
  ) => {
    const validator = (
      to_employee_ids: number[] | undefined,
      amount: number | undefined
    ) => {
      if (
        !amount ||
        !currentEmployeeTips ||
        !to_employee_ids ||
        to_employee_ids.length <= 0
      ) {
        return;
      }
      if (currentEmployeeTips.remain_point < to_employee_ids.length * amount) {
        const needPoints = to_employee_ids.length * amount;
        return `${needPoints}ポイント必要です。${
          needPoints - currentEmployeeTips.remain_point
        }ポイント足りません`;
      }
    };
    return (...args: Parameters<typeof validator>) => {
      const res = validator(...args);
      const crossFieldKey =
        type === 'to_employee_ids' ? 'amount' : 'to_employee_ids';
      res
        ? setError(crossFieldKey, { type: 'custom', message: res })
        : clearErrors(crossFieldKey);
      return res;
    };
  };

  const onSubmit = handleSubmit(
    async ({ to_employee_ids, amount, message }) => {
      const messageWithHashTags = addHashTagsToMessage(
        message,
        selectedTeamHashTags
      );
      try {
        if (to_employee_ids.length > 1) {
          await api.createCards(tenantId, {
            to_employee_ids,
            amount,
            message: messageWithHashTags,
          });
        } else {
          await api.createCard(tenantId, {
            to_employee_id: to_employee_ids[0],
            amount,
            message: messageWithHashTags,
          });
        }
        reset();
        setSelectedTeamHashTags([]);
        currentEmployeeMutate();
        cardsMutate();
        notifySuccess('エモチップを送信しました!');
      } catch (error) {
        if (error instanceof Error) {
          rollbar.error(error);
          notifyError(error.message);
        } else {
          notifyError('エモチップの送信に失敗しました');
        }
      }
    }
  );

  useEffect(() => {
    reset({
      to_employee_ids: undefined,
      amount: undefined,
      message: '',
    });
  }, [reset]);

  const employeeOptions = useMemo(() => {
    if (!employees) {
      return [];
    }
    return employees.reduce((prev, next) => {
      if (!next || !next.employees) {
        return prev;
      }
      const empls = next.employees.map((e) => convertEmployeeToSelectOption(e));
      return [...prev, ...empls];
    }, [] as EmployeeOption[]);
  }, [employees]);
  const teamHashtagOptions = useMemo(() => {
    return teamHashTags.map(convertTeamHashTagToSelectOption);
  }, [teamHashTags]);

  const validateMaxMessageWithHashTags = (
    inputMessage: string
  ): string | undefined => {
    if (
      addHashTagsToMessage(inputMessage, selectedTeamHashTags).length >
      messageMaxLength
    ) {
      return 'チームハッシュタグを含めて400文字以内で入力してください';
    }
    return;
  };

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <ToEmployeeAndPointArea>
          <ToEmployeeSelectArea>
            <FormLabel>
              送る相手
              <ToEmployeeError error={errors.to_employee_ids} />
            </FormLabel>
            <Controller
              name="to_employee_ids"
              control={control}
              rules={{
                required: '送る相手を選択してください',
                validate: {
                  validateAmount: (value, formValues) => {
                    return validateAmount('to_employee_ids')(
                      value,
                      formValues.amount
                    );
                  },
                },
              }}
              render={({ field: { onChange } }) => (
                <Select
                  options={employeeOptions}
                  placeholder="送りたい相手を検索する"
                  formatOptionLabel={(option: EmployeeOption) => (
                    <EmployeeSelectLabel option={option} />
                  )}
                  getOptionLabel={(option) =>
                    `${option.label} ${option.realName}`
                  }
                  styles={selectStyles(!!errors.to_employee_ids)}
                  isMulti
                  isClearable
                  onChange={(choices) => onChange(choices.map((e) => e.value))}
                />
              )}
            />
          </ToEmployeeSelectArea>
          <PointInputArea>
            <FlexItem>
              <FormLabel>
                ポイント
                <FormSubLabel error={!!errors.amount}>(0-120)</FormSubLabel>
              </FormLabel>
              <PointInput
                placeholder="例: 39"
                error={!!errors.amount}
                {...register('amount', {
                  required: true,
                  min: 0,
                  max: 120,
                  validate: {
                    validateAmount: (value, formValues) => {
                      return validateAmount('amount')(
                        formValues.to_employee_ids,
                        value
                      );
                    },
                  },
                })}
              />
            </FlexItem>
          </PointInputArea>
        </ToEmployeeAndPointArea>
        <FlexItem>
          <FormLabel>
            メッセージ
            {errors.message && (
              <ErrorLabel>{errors.message.message}</ErrorLabel>
            )}
          </FormLabel>
          <MessageTextArea
            placeholder="例: いつもありがとうございます！ #Speed"
            minRows={2}
            error={!!errors.message}
            {...register('message', {
              required: '1文字以上入力してください',
              maxLength: {
                value: messageMaxLength,
                message: '400文字以内で入力してください',
              },
              validate: {
                maxMessageWithHashTags: validateMaxMessageWithHashTags,
              },
            })}
          />
        </FlexItem>
        <FlexItem>
          <FormLabel>チームハッシュタグ</FormLabel>
          <Select
            options={teamHashtagOptions}
            isMulti
            placeholder="チームハッシュタグを選ぶ"
            styles={selectStyles()}
            value={selectedTeamHashTags}
            onChange={handleChangeTeamHashTagSelect}
            isClearable
          />
        </FlexItem>
        <ButtonWrapper>
          <PrimaryButton disabled={!isValid}>送る</PrimaryButton>
        </ButtonWrapper>
      </Form>
    </Container>
  );
};
