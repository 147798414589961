import Link from 'next/link';
import { FC } from 'react';
import styled from 'styled-components';

const TabList = styled.ul`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => `${theme.colors.base.border.main}`};
  width: 100%;
`;

const TabItem = styled.li<{
  active: boolean;
}>`
  color: ${({ active, theme }) => active && theme.colors.primary.main};
  min-width: 96px;
  padding: 0 0.5em;
  text-align: center;
  border-bottom: ${({ active, theme }) =>
    active && `4px solid ${theme.colors.primary.main}`};
  &:hover {
    color: ${({ theme }) => theme.colors.primary.main};
    cursor: pointer;
  }
`;

const TabLink = styled(Link)`
  display: block;
  width: 100%;
`;

export type TabProps = {
  tabs: { name: string; active: boolean; url: string }[];
};

export const Tab: FC<TabProps> = ({ tabs }) => {
  return (
    <TabList>
      {tabs.map((tab) => {
        return (
          <TabItem key={tab.name} active={tab.active}>
            <TabLink href={tab.url}>{tab.name}</TabLink>
          </TabItem>
        );
      })}
    </TabList>
  );
};
