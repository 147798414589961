import { Layout, Loading, TipCounters } from '@components/common';
import {
  useCurrentEmployee,
  useCurrentEmployeeTips,
  useAllEmployees,
  useTeamHashTags,
  useCards,
  type UseCardsOptions,
} from '@hooks';
import { useRouter } from 'next/router';
import { Tab } from '@components/common/Tab';
import { CardList } from '@components/common/CardList';
import styled from 'styled-components';
import { CardCreateForm } from './CardCreateForm';
import { CardListPlaceholder } from '@components/common/CardListPlaceholder';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  gap: 32px;
`;

/**
 * 以下の4画面を共通の NextPage としてまとめる。
 * - `/` or `/cards`: tenant 内のすべての投稿一覧
 * - `/cards/received`: ログインユーザー自身がもらった投稿一覧
 * - `/cards/sent`: ログインユーザー自身が送った投稿一覧
 * - `/cards/clapped`: ログインユーザー自身が拍手した投稿一覧
 */
type Props = {
  cardType: UseCardsOptions['type'];
};

export const CardsPage = (props: Props) => {
  const router = useRouter();
  const currentPath = router.pathname;
  const { data: currentEmployee, mutate: currentEmployeeMutate } =
    useCurrentEmployee();
  const { data: currentEmployeeTips } = useCurrentEmployeeTips();
  const {
    fetchedCardsData,
    cardsMutate,
    handleClickLoadButton,
    isLoadingMore,
    isReachingEnd,
  } = useCards({
    type: props.cardType,
    tenantId: currentEmployee?.tenant_id,
    employeeId: currentEmployee?.id,
  });
  const { fetchedEmployeesData } = useAllEmployees(currentEmployee?.tenant_id);
  const { data: allTeamHashTags } = useTeamHashTags(currentEmployee?.tenant_id);

  if (!currentEmployee) {
    return <Loading />;
  }

  const rootPath = '/';
  const allCardsPath = '/cards';
  const receivedCardsPath = `/cards/received`;
  const sentCardsPath = `/cards/sent`;
  const clappedCardsPath = `/cards/clapped`;

  const tabs = [
    {
      name: 'すべて',
      active: currentPath === allCardsPath || currentPath === rootPath,
      url: allCardsPath,
    },
    {
      name: 'もらった',
      active: currentPath.includes(receivedCardsPath),
      url: receivedCardsPath,
    },
    {
      name: '送った',
      active: currentPath.includes(sentCardsPath),
      url: sentCardsPath,
    },
    {
      name: '拍手した',
      active: currentPath.includes(clappedCardsPath),
      url: clappedCardsPath,
    },
  ];

  return (
    <Layout
      employee={currentEmployee}
      tipCounters={
        <TipCounters
          monthlyRecieved={currentEmployeeTips?.received_point_in_this_month}
          canSendThisWeek={currentEmployeeTips?.remain_point}
        />
      }
    >
      <Container>
        {(currentPath === allCardsPath || currentPath === rootPath) && (
          <CardCreateForm
            tenantId={currentEmployee.tenant_id}
            employees={fetchedEmployeesData}
            currentEmployeeTips={currentEmployeeTips}
            teamHashTags={allTeamHashTags?.team_hash_tags ?? []}
            currentEmployeeMutate={currentEmployeeMutate}
            cardsMutate={cardsMutate}
          />
        )}
        <Tab tabs={tabs} />
        {!fetchedCardsData ? (
          <CardListPlaceholder />
        ) : (
          <CardList
            fetchedCardsData={fetchedCardsData}
            currentEmployeeId={currentEmployee.id}
            currentEmployeeMutate={currentEmployeeMutate}
            cardsMutate={cardsMutate}
            handleClickLoadButton={handleClickLoadButton}
            isLoadingMore={isLoadingMore}
            isReachingEnd={isReachingEnd}
          />
        )}
      </Container>
    </Layout>
  );
};
